import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  from,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { confirm } from '~common/services/modalService';
import React from 'react';
import possibleTypes from '../../../possibleTypes.json';
import { isRunningOnClientSide, isRunningOnServerSide } from '~lib/util';

import { customFetch } from '~lib/customFetch';
const { createUploadLink } = require('apollo-upload-client');

const getUri = () => {
  if (process.env.DHF_GRAPHQL_URI) {
    return process.env.DHF_GRAPHQL_URI;
  }

  if (isRunningOnClientSide()) {
    return '/graphql';
  }

  if (process.env.DHF_API_HOSTNAME) {
    return `https://${process.env.DHF_API_HOSTNAME}/graphql`;
  }

  return `http://localhost:${process.env.PORT || '9000'}/graphql`;
};

// Saves the request id from the response so it can be resent with future requests
const requestIdLink = new ApolloLink((operation, forward) => {
  if (isRunningOnServerSide()) {
    return forward(operation);
  }

  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const requestId = headers.get('x-request-id');
      if (requestId) {
        sessionStorage.setItem('REQ_ID', requestId);
      }
    }

    return response;
  });
});

const appVersionLink = setContext((request, prevContext = {}) => {
  if (isRunningOnServerSide()) {
    return {};
  }

  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      'x-app-version': process.env.BUILD_ID || 'local',
    },
  };
});

const authLink = setContext(async (request, prevContext) => {
  if (isRunningOnServerSide()) {
    return {};
  }

  const authHeaders = { ...prevContext.headers };

  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    if (token) {
      authHeaders.authorization = `Bearer ${token}`;
    }

    if (!authHeaders['x-request-id']) {
      let REQ_ID = sessionStorage.getItem('REQ_ID');
      if (!REQ_ID) {
        REQ_ID = uuidv4();
      }
      authHeaders['x-request-id'] = REQ_ID;
    }
  } catch (err) {}

  return { headers: authHeaders };
});

const httpLink = split(
  operation => operation.getContext().hasUpload,
  createUploadLink({
    uri: getUri(),
    fetch: customFetch,
    credentials: 'same-origin',
    headers: { 'Apollo-Require-Preflight': 'true' },
    fetchOptions: {
      credentials: 'same-origin',
    },
  }),
  new BatchHttpLink({
    uri: getUri(),
    batchMax: 10,
    batchInterval: 100,
    fetch: customFetch,
    includeExtensions: true,
    credentials: 'same-origin',
    fetchOptions: {
      credentials: 'same-origin',
    },
  })
);

const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      if (message !== 'PersistedQueryNotFound') {
        console.warn(
          `[GraphQL error] ${message} ${path ? `(${path.join(' -> ')})` : ''})`
        );
      }

      if (
        extensions &&
        extensions.code &&
        ['UNAUTHENTICATED', 'EAUTH'].includes(extensions.code)
      ) {
        console.error(graphQLErrors);
        confirm({
          title: 'Unexpected problems with your account',
          alert: {
            type: 'error',
            message: (
              <>
                We are having problems with your account.
                <p>(code = UNAUTHENTICATED_HAMBS)</p>
                <p>
                  Please contact our support on <strong>1800 226 126</strong>.
                </p>
              </>
            ),
          },
          confirmText: 'OK',
        });
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
};

const client = new ApolloClient({
  name: 'DHF Website',
  version: process.env.ENGINE_SCHEMA_TAG || 'dev',
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: defaultDataIdFromObject,
    possibleTypes,
  }),
  // ssrMode: isRunningOnServerSide(),
  link: from([
    authLink,
    appVersionLink,
    requestIdLink,
    // createPersistedQueryLink(),
    onError(errorHandler),
    httpLink,
  ]),
});

export default client;
