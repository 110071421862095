import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { Flex, Box, Button } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { navigate } from 'gatsby';

import Icon from '~common/atoms/Icon';
import HTMLContent from 'components/Elements/HTMLContent';
import { AuthContext } from 'context/sso';
import { isRunningOnClientSide } from '~lib/util';
import Logo from '~common/atoms/Logo';

export const ActionLink = ({ onClick, label }) => {
  return (
    <Button variant="primaryInverted" onClick={onClick}>
      {label}
    </Button>
  );
};
export const Actions = ({ actions }) => {
  const auth = useContext(AuthContext);

  if (typeof actions !== 'object') {
    actions = { logout: true, home: false };
  }

  if (!Object.keys(actions).some(Boolean)) {
    return null;
  }

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {actions.logout && (
        <ActionLink onClick={() => auth.logout()} label="Return to log in" />
      )}
      {actions.home && (
        <ActionLink onClick={() => navigate('/')} label="Return to home page" />
      )}
    </Flex>
  );
};

export const Container = ({ logo, actions, requestId, error, children }) => {
  const id = isRunningOnClientSide() ? sessionStorage.getItem('REQ_ID') : null;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      width={1}
      height={{ sm: '100vh' }}
      py={3}
      px={{ xs: 3, sm: 0 }}
    >
      {logo && <Logo />}
      <Box my="40px">{children}</Box>
      {actions && <Actions actions={actions} />}
      {requestId && id && (
        <div
          css={css`
            width: 100%;
            padding: 40px 0;
            text-align: center;
            font-size: 12px;
            font-family: Menlo, monospace;
            line-height: 18px;
            color: #444;
          `}
        >
          Request ID: {id}
        </div>
      )}
      {process.env.NODE_ENV === 'development' && error && (
        <pre
          css={css`
            font-family: Menlo, monospace;
            font-size: 12px;
            color: #333;
          `}
        >
          {error.stack}
        </pre>
      )}
    </Flex>
  );
};

export const Message = ({ type, text, color, align, icon, error }) => {
  const { theme } = useThemeUI();

  if (!color) {
    color = type ? theme.colors.alert[type].color : theme.colors.black;
  }

  if (!align) {
    align = { xs: 'center', sm: 'left' };
  }

  if (icon === true || icon === undefined) {
    icon = {
      error: 'restricted',
      success: 'oms-horn',
      info: 'oms-horn',
    }[type];
  }

  return (
    <Flex flexDirection="row">
      {icon && (
        <Icon
          name={icon}
          fill={color}
          width="30px"
          height="30px"
          className="hide-mobile"
        />
      )}
      <Box ml={{ xs: 0, sm: icon ? 3 : 0 }} textAlign={align}>
        <HTMLContent
          markdown={text}
          css={css`
            color: ${color};

            a {
              color: ${theme.colors.primary};
              font-weight: 600;
              text-decoration: underline;
            }

            ul,
            ol {
              color: ${theme.colors.text.dark};

              li {
                color: ${theme.colors.text.dark};
              }
            }

            ${theme.mq.sm} {
              p {
                margin-bottom: 0;
              }
            }
          `}
        />
      </Box>
    </Flex>
  );
};

const ErrorPage = ({
  logo,
  actions,
  requestId,
  type,
  icon,
  color,
  align,
  text,
  error,
}) => {
  return (
    <Container
      logo={logo}
      actions={actions}
      requestId={requestId}
      error={error}
    >
      <Message
        type={type}
        icon={icon}
        color={color}
        align={align}
        text={text}
        error={error}
      />
    </Container>
  );
};

ErrorPage.defaultProps = {
  logo: true,
  type: 'error',
  text: `
We are currently experiencing issues processing this request.

We are working hard to fix things, so please try again later.
  `,
};

export default ErrorPage;
