export const PERSON_ROLE = {
  DEPENDANT: 'DEPENDANT',
  PARTNER: 'PARTNER',
  MEMBER: 'MEMBER',
};

export const COMMUNICATION_PREFERENCE = {
  EMAIL: 'EMAIL',
  POST: 'POST',
};
