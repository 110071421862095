import React from 'react';
import styled from '@emotion/styled';
import { P } from '../../../common/atoms/typography';
import { Box } from 'rebass';
import { withTheme } from '@emotion/react';

import { MarketoForm } from '~common/organisms/Marketo';
import Logo from '~common/atoms/Logo';

const StyledFormWrapper = styled(Box)``;

const StyledLogo = styled(Logo)`
  display: block;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

const StyledTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes[5]}px;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

const EmailUsForm = ({
  onCancel,
  onSubmit,
  theme,
  className,
  loading = false,
  isAnomUser,
  initialValues,
  title,
  actions,
  ...props
}) => {
  return (
    <StyledFormWrapper
      width={{
        xs: 1,
        tabletP: 400,
      }}
      pb={3}
      className={className}
      {...props}
    >
      <StyledLogo />
      {title
        ? title
        : title !== false && (
            <>
              <StyledTitle marginBottom={theme.space[3]}>Email us</StyledTitle>
              <P marginBottom={theme.space[3]}>
                Tell us your enquiry and we will reply to your email address.
              </P>
            </>
          )}
      <MarketoForm
        loading={loading}
        values={initialValues}
        formId={process.env.MARKETO_EMAIL_US_FORM_FORM_ID}
        onSubmit={onSubmit}
      />
    </StyledFormWrapper>
  );
};

export default withTheme(EmailUsForm);
