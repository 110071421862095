import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import emotionReset from 'emotion-reset';
import { useLocation } from '@reach/router';
import { ApolloProvider } from '@apollo/client/react/context';
import { Global, css } from '@emotion/react';
import client from '~lib/client';
import ThemeProvider from '~lib/providers/ThemeProvider';
import { useThemeUI } from 'theme-ui';

import { BrowserContext } from '../context';
import {
  isMobile,
  isDevelopment,
  isProduction,
  isRunningOnClientSide,
  isRunningOnServerSide,
} from '~lib/util';

import { AuthProvider } from '../context/sso';
import { ContactUsProvider } from '~OMS/contact-us/ContactUsContext';
import SentryErrorBoundary from './SentryErrorBoundary';
import { LocaleProvider } from '~common/locales';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import useBranding from '~lib/hooks/sanity/useBranding';

const globalStyles = props => css`
  ${emotionReset}

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  html,
  body {
    @font-face {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      src: url('fonts/proximanova-regular.otf'),
        url('fonts/proximanova-regular.woff') format('woff');
    }

    @font-face {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: bold;
      src: url('fonts/proximanova-bold.otf'),
        url('fonts/proximanova-bold.woff') format('woff');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: normal;
      font-weight: 400;
      src: url('/fonts/SharpSansDispNo1-Book.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: italic;
      font-weight: 400;
      src: url('/fonts/SharpSansDispNo1-BookIt.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: normal;
      font-weight: 500;
      src: url('/fonts/SharpSansDispNo1-Medium.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: italic;
      font-weight: 500;
      src: url('fonts/SharpSansDispNo1-MediumIt.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: normal;
      font-weight: 600;
      src: url('/fonts/SharpSansDispNo1-Semibold.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: italic;
      font-weight: 600;
      src: url('fonts/SharpSansDispNo1-SemiboldIt.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Sharp Sans Display No1';
      font-style: normal;
      font-weight: 700;
      src: url('/fonts/SharpSansDispNo1-Bold.woff2') format('woff2');
    }

    font-family: ${props.theme.fonts.sans};
    font-size: ${props.theme.fontSizes[2]}px;
    height: auto;
    background-color: ${props.darkBackground
      ? props.theme.colors.background.base
      : ''};
  }

  #___gatsby {
    position: inherit !important;
  }

  #___gatsby,
  #___gatsby > div,
  #___gatsby > div > div {
    -webkit-overflow-scrolling: touch;
  }

  input,
  textarea,
  select,
  button,
  table,
  th,
  td,
  svg,
  svg text {
    font-family: ${props.theme.fonts.sans} !important;
  }

  strong {
    font-weight: 700;
  }

  button {
    font-weight: 600;
  }

  a,
  button {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }

  .hide-mobile {
    @media only screen and (max-width: 992px) {
      display: none !important;
    }
  }

  .hide-desktop {
    @media only screen and (min-width: 992px) {
      display: none !important;
    }
  }

  .show-mobile-block {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: block !important;
    }
  }
  .show-mobile-inline {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: inline !important;
    }
  }
  .show-mobile-inline-block {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: inline-block !important;
    }
  }
  .show-mobile-flex {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: flex !important;
    }
  }

  .aria-modal-underlay {
    padding-top: 2em;
    @media screen and (max-width: 992px) {
      padding-top: 0;
    }
  }

  .full-width {
    width: 100%;
  }

  .align-h {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .aria-modal-dialog {
    background-color: ${props.theme.colors.background.lightest};
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #979797;
    max-width: 960px;
    padding: 20px 30px;
    position: relative;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding: 10px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
`;

const SITE_META_DATA_QUERY = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

const PreventSSR = ({ children }) => {
  return <>{isRunningOnClientSide() && children}</>;
};

const Body = ({ children }) => {
  const [firstRender, setFirstRender] = useState(false);
  const location = useLocation();
  const darkBackground =
    location.pathname.includes('oms/sso/login') ||
    (location.pathname.includes('oms') &&
      !location.pathname.includes('oms/sso'));
  const { theme } = useThemeUI();

  useEffect(() => {
    setFirstRender(true);
  }, []);

  // unfortunately, there are a lot of functions that rely on window being defined
  // SSR is scuffed, so we need to prevent the page from rendering until the client side
  // this fixes the hydration error
  if (!firstRender) {
    return null;
  }

  return (
    <>
      <Global
        styles={globalStyles({
          darkBackground,
          theme,
        })}
      />
      <BrowserContext.Provider
        value={{
          isMobile,
          isProduction,
          isDevelopment,
          isRunningOnClientSide: isRunningOnClientSide(),
          isRunningOnServerSide: isRunningOnServerSide(),
          location,
        }}
      >
        <ApolloProvider client={client}>
          <ContactUsProvider>
            <AuthProvider>
              <PreventSSR>{children}</PreventSSR>
            </AuthProvider>
          </ContactUsProvider>
        </ApolloProvider>
      </BrowserContext.Provider>
    </>
  );
};

/**
 * Include structured data tag in index page.
 *
 * @return structured data script built using DHF_BASE_URL
 */
const StructuredData = () => {
  const DHF_BASE_URL = process.env.DHF_BASE_URL;
  const { phone, organisationName } = useContactDetails();
  const { logo } = useBranding();
  console.log('injecting structured script');
  return (
    <script type="application/ld+json">
      {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "${organisationName}",
          "url": "${DHF_BASE_URL}",
          "logo": "${logo?.asset?.url}",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "${phone}",
            "contactType": "customer service",
            "areaServed": "AU",
            "availableLanguage": "en"
          }}`}
    </script>
  );
};

const Root = ({ children, pageContext }) => {
  const data = useStaticQuery(SITE_META_DATA_QUERY);
  const metadata = {
    title: pageContext?.title || data.site.siteMetadata.title,
    description: pageContext?.description || data.site.siteMetadata.description,
    keywords: pageContext?.keywords,
  };

  const DHF_SEO_TOGGLE = process.env.DHF_SEO_TOGGLE || 'TRUE';

  return (
    <div>
      <Helmet>
        {DHF_SEO_TOGGLE.toUpperCase() === 'TRUE' &&
          pageContext?.slug?.current === 'home' && <StructuredData />}
        <html lang="en" />
        <title>{metadata.title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        {isProduction && (
          <script
            src="//script.crazyegg.com/pages/scripts/0084/4688.js"
            async="async"
          />
        )}
        {process.env.DHF_WAF_CHALLENGE_SCRIPT && (
          <script async src={`${process.env.DHF_WAF_CHALLENGE_SCRIPT}`} />
        )}
      </Helmet>

      <ThemeProvider>
        <LocaleProvider>
          <SentryErrorBoundary>
            <Body>{children}</Body>
          </SentryErrorBoundary>
        </LocaleProvider>
      </ThemeProvider>
    </div>
  );
};

export default Root;
