import { useCallback, useState } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { getConversionErrorMessage } from './sso-messages';

export default () => {
  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const submit = useCallback(
    async ({ membershipId }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        // endpoint can be any
        await axios
          .post('/conversion/authenticate', {
            membershipId,
          })
          .then(response => {
            setState(currentState => ({
              ...currentState,
              error: undefined,
              loading: false,
            }));
            // just a fallback - for register access the server does not respond with a 200
            if (response.data.code === 'OK') {
              (async () => {
                await navigate(`/oms/sso/verification/step-1`, {
                  state: {
                    user: {
                      membershipId,
                    },
                  },
                });
              })();
            }
          });
      } catch (error) {
        // has the policy already been converted? 401 or 412
        setState(currentState => ({
          ...currentState,
          error: undefined,
          loading: false,
        }));
        // handle 412 with re-direct, already registered
        if (error.response.status === 412) {
          // @TODO forward to new screen from tara
          await navigate(`/oms/sso/alreadyRegistered`, {
            state: {
              user: {
                membershipId,
              },
            },
          });
          // handle 401 with re-direct, not registered yet
        } else if (error.response.status === 401) {
          await navigate(`/oms/sso/verification/step-1`, {
            state: {
              user: {
                membershipId,
              },
            },
          });
        } else {
          // any other error with message
          const message = getConversionErrorMessage(error);
          setState(currentState => ({
            ...currentState,
            error: { message },
            loading: false,
          }));
        }
      }
    },
    [state]
  );

  return {
    actions: {
      submit,
    },
    state,
  };
};
