import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import Icon from '~common/atoms/Icon';
import { P2 } from './typography';
import { useThemeUI } from 'theme-ui';

const StyledIconHeading = styled.div`
  font-size: ${props => props.theme.fontSizes[2]}px;
  margin-bottom: ${props => (props.mb ? props.mb : '15px')};
`;

const StyledIcon = styled(Icon)`
  /* transform: translateY(-6px); */
`;

const IconHeading = ({ icon, mb, text, fill, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <StyledIconHeading mb={mb}>
      <Flex alignItems="center">
        <Box width={1 / 10} mr="12px">
          <StyledIcon
            name={icon}
            fill={theme.colors.secondary}
            width={props.width ? props.width : '24px'}
            height="24px"
          />
        </Box>
        <P2 variant="semiBold" color={theme.colors.primary}>
          {text}
        </P2>
      </Flex>
    </StyledIconHeading>
  );
};

export default IconHeading;
