import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { AVANT_LOGIN_URL } from '~OMS/SSO/constants';

const avantApi = axios.create();

export default ({ getToken }) => {
  const [isLinked, setIsLinked] = useState(false);
  const [unlinking, setUnlinking] = useState(false);
  const [goingToAvant, setGoingToAvant] = useState(false);
  const [checkingLinkage, setCheckingLinkage] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    avantApi.interceptors.request.use(
      async config => {
        const token = await getToken();
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    //eslint-disable-next-line
  }, []);

  const goToAvant = useCallback(async () => {
    try {
      setGoingToAvant(true);
      const responsePayload = (await avantApi.get('/sso/goToAvant')).data;
      window.location.href = responsePayload.redirectUrl;
    } catch (err) {
      // if anything goes wrong just redirect to avant's login page
      window.location.href = `${AVANT_LOGIN_URL}`;
    }
  }, []);

  const checkLinkage = useCallback(() => {
    setCheckingLinkage(true);
    return avantApi
      .get('/sso/isLinked')
      .then(response => {
        setIsLinked(response.data.isLinked);
        setCheckingLinkage(false);
      })
      .catch(error => {
        setCheckingLinkage(false);
        setError(error);
        console.error(error);
      });
  }, []);

  const unlink = useCallback(() => {
    setUnlinking(true);
    return avantApi
      .post('/sso/unlinkAvantAccount')
      .then(() => {
        setIsLinked(false);
        setUnlinking(false);
      })
      .catch(error => {
        setUnlinking(false);
        console.error(error);
        setError(error);
      });
  }, []);

  return {
    state: {
      isLinked,
      checkingLinkage,
      unlinking,
      error,
      goingToAvant,
    },
    actions: {
      checkLinkage,
      goToAvant,
      unlink,
    },
  };
};
